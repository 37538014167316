_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Ange det matchande v\u00e4rdet f\u00f6r {0}",
	"crm.label.field":"F\u00e4lt",//no i18n
"crm.label.value":"V\u00e4rde",//no i18n
	"sentiment.criteria.wrongcriteria":"Kriteriev\u00e4rdet kan inte vara h\u00f6gre \u00e4n {0}",//no i18n
"crm.mb.field.common.splc":"Specialtecken till\u00e5ts inte. Ange ett giltigt v\u00e4rde.",//no i18n
	"crm.label.field.plural":"f\u00e4lt",//no i18n
	"crm.label.in.minutes":"{0} (i minuter)",//no i18n
	"crm.security.roles.list":"Rollista",//no i18n
"crm.security.roles.lookup.info":"V\u00e4lj en roll fr\u00e5n listan.",//no i18n
"crm.territory.addterritory":"L\u00e4gg till territorium",//no i18n
"crm.title.edit.territory":"Redigera territorium",//no i18n
"crm.territory.title.assign.territories":"Tilldela territorier",//no i18n
	"crm.label.context.help":"Hj\u00e4lp",//no i18n
	"crm.label.from":"Fr\u00e5n",//no i18n
"crm.label.to":"Till",//no i18n
	"workflow.option.webhookFailure.fromDate":"Fr\u00e5n-datum",//no i18n
"workflow.option.webhookFailure.toDate":"Till-datum",//no i18n
"crm.custom.field.less.than.equalto":"{0} ska vara mindre \u00e4n eller lika med {1}.",//no i18n
	"crm.template.listview.search.no.results":"Inga resultat hittades",//No I18n
	"crm.label.tag.new":"Ny tagg",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"Fr\u00e5n-datum kan inte vara senare \u00e4n till Till-datum.",//no i18n
	"crm.label.enter.tag":"Ange taggar",//No I18n
	"crux.comboBox.max.limit":"Du kan inte v\u00e4lja fler \u00e4n {0} {1}.",//NO I18n
	"Administrator":"Administrat\u00f6r",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"L\u00e4gg till",//NO I18n
	"crm.label.users":"Anv\u00e4ndare", //NO I18n
  "crm.workflow.alert.roles":"Roller", //NO I18n
  "crm.security.groups":"Grupper", //NO I18n
	"crm.label.available" : "Tillg\u00e4nglig", //NO I18n
	"crm.label.assign.manually" : "Tilldela", //NO I18n
	"crm.globalsearch.option.all": "Alla", //NO I18n
	"webform.status.Active":"Aktiv", //NO I18n
	"Inactive":"Inaktiv", //NO I18n
  "Confirmed":"Bekr\u00e4ftat", //NO I18n
  "crm.user.component.unconfirmed":"Obekr\u00e4ftat",//no i18n
  "DeletedUser":"Raderad", //NO I18n
  "crm.feed.group.admin":"Admin", //NO I18n
  "crm.ln.lable.current":"Aktuell", //NO I18n
	"crm.label.selected": "Vald",//NO I18n
	"crm.auditlog.user": "Anv\u00e4ndare", //NO I18n
	"cob.role": "Roll", //NO I18n
	"zoho.email": "E-post", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Hittade inga anv\u00e4ndare.", //NO I18n
	"crm.label.picklist.none": "Ingen", //NO I18n
	"crm.usrpop.non.selected" : "Valda anv\u00e4ndare",//NO I18n
	"crm.zti.label.user": "Anv\u00e4ndarnamn", //NO I18n
	"crm.label.notSelected" : "Ej vald",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Samtal",//NO I18n
	"crm.phoneNo.Link.Title" : "Ring med Skype",//NO I18n
	"crm.button.cancel" : "Avbryt",//NO I18n
	"crm.button.save" : "Spara",//NO I18n
	"crm.no.data.found" : "Inga data hittades.",//NO I18n
	"crm.label.no.options.found" : "Inga alternativ hittades.",//No I18n
	"crm.globalsearch.search.title" : "S\u00f6k",//No I18n
	"None" : "Ingen",//No I18n
	"crm.label.criteria.pattern" : "Kriteriem\u00f6nster",//No I18n
	"crm.label.edit.criteria.pattern" : "Redigera m\u00f6nster",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "M\u00f6nsterparenteserna matchar inte.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Ogiltiga parenteser omger villkorsoperat\u00f6ren.",//No I18n
	"crm.criteria.number.notmatch.check" : "Kontrollera m\u00f6nstret p\u00e5 {0}",//No I18n
	"criteria.error.alert.other.params" : "Ogiltigt inneh\u00e5ll i detta m\u00f6nster.", //No I18n
	"crm.label.search.for.users": "S\u00f6k anv\u00e4ndare", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Kriteriem\u00f6nster matchar inte de villkor du har valt.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Kriteriem\u00f6nster matchar inte de villkor du har valt.", //No I18n
	"and" : "och", //No I18n
	"or" : "eller", //No I18n
	"crm.label.or" : "ELLER", //No I18n
	"crm.label.and" : "OCH", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Ange en giltig f\u00e4ltetikett p\u00e5 raden {0}", //No I18n
	"crm.criteria.condition.valid.check" : "Ange ett giltigt f\u00f6rh\u00e5llande f\u00f6r {0}", //No I18n
	"crm.field.valid.check" : "Ange en giltig {0}.", //No I18n
	"crm.custom.field.less.than.to" : "<i>Fr\u00e5n</i>-omr\u00e5de kan inte vara st\u00f6rre \u00e4n <i>Till</i>-omr\u00e5de.", //No I18n
	"crm.alert.label.savepattern" : "Spara m\u00f6nster innan du \u00e4ndrar kriterierna.",//No I18n
	"is" : "\u00e4r",//No I18n
	"isn\'t" : "\u00e4r inte",//No I18n
	"contains" : "inneh\u00e5ller",//No I18n
	"doesn\'t contain" : "inneh\u00e5ller inte",//No I18n
	"starts with" : "b\u00f6rjar med",//No I18n
	"ends with" : "slutar med",//No I18n
	"is empty" : "\u00e4r tom",//No I18n
	"is not empty" : "\u00e4r inte tom",//No I18n
	"is before" : "\u00e4r f\u00f6re",//No I18n
	"is after" : "\u00e4r efter",//No I18n
	"between" : "mellan",//No I18n
	"not between" : "inte mellan",//No I18n
	"Today" : "Idag",//No I18n
	"Tommorow" : "I morgon",//No I18n
	"Tommorow Onwards" : "Med b\u00f6rjan imorgon",//No I18n
	"Yesterday" : "Ig\u00e5r",//No I18n
	"Till Yesterday" : "Till ig\u00e5r",//No I18n
	"Last Month" : "F\u00f6rra M\u00e5naden",//No I18n
	"Current Month" : "Innevarande m\u00e5nad", //No I18n
	"Next Month" : "N\u00e4sta m\u00e5nad", //No I18n
	"Last Week" : "F\u00f6rra Veckan", //No I18n
	"Current Week" : "Innevarande vecka", //No I18n
	"Next Week" : "N\u00e4sta vecka", //No I18n
	"Age in Days" : "\u00c5lder i dagar", //No I18n
	"Due in Days" : "F\u00f6rfaller om n\u00e5gra dagar", //No I18n
	"Scheduled" : "Schemalagt", //No I18n
	"Attended Dialled" : "N\u00e4rvarade uppringda", //No I18n
	"Unattended Dialled" : "Ej uppringd fr\u00e5nvaro", //No I18n
	"Overdue" : "F\u00f6rsenad", //No I18n
	"Cancelled" : "Annullerad", //No I18n
	"Received" : "Mottagen", //No I18n
	"Missed" : "Saknade", //No I18n
	"crm.alert.character.not.allowed" : "{0} \u00e4r inte till\u00e5ten", //No I18n
	"crm.condition.in.last" : "senaste", //No I18n
	"crm.zinvoice.dueIn" : "f\u00f6rfaller om", //No I18n
	"on" : "Den",//No I18n
	"before" : "f\u00f6re",//No I18n
	"crm.label.general.small.after" : "efter",//No I18n
	"crm.thisweek" : "Denna vecka",//No I18n
	"crm.label.this.month" : "Denna m\u00e5nad",//No I18n
	"crm.thisyear" : "Detta \u00e5r",//No I18n
	"crm.source.user.and.system" : "Anv\u00e4ndare & System",//No I18n
	"crm.source.user.or.system" : "Anv\u00e4ndare eller system",//No I18n
	"crm.label.system2" : "System",//No I18n
	"crm.source.user.only" : "Endast via anv\u00e4ndare",//No I18n
	"crm.source.system.only" : "Endast av system",//No I18n
	"crm.condition.till.today" : "Till idag",//No I18n
	"game.month.left" : "1 m\u00e5nad",//No I18n
	"game.months.left" : "{0} m\u00e5nader",//No I18n
	"crm.condition.last.30.days" : "under de senaste 30 dagarna",//No I18n
	"crm.condition.last.60.days" : "under de senaste 60 dagarna",//No I18n
	"crm.condition.last.90.days" : "under de senaste 90 dagarna",//No I18n
	"crm.label.filter.typehere" : "Skriv h\u00e4r", //No I18N
	"crm.filter.is.not" : "\u00e4r inte", //No I18n
	"crm.condition.until.now" : "Tills nu",//No I18n
	"crm.filter.email.isblocked" : "\u00e4r blockerad",//No I18n
	"crm.filter.email.isnotblocked" : "\u00e4r ej blockerad",//No I18n
	"crm.label.no.results.match" : "Inga resultat st\u00e4mmer",//No I18n
	"crm.label.select.user" : "Klicka f\u00f6r att v\u00e4lja anv\u00e4ndare.", //No I18n
	"current.logged.in.user": "Inloggad anv\u00e4ndare", //NO I18n
	"current.logged.in.user.definition": "Anv\u00e4ndaren som initierar inspelnings\u00e5tg\u00e4rden.", //NO i18n
	"crm.security.group": "Grupp", //NO I18n
	"crm.security.role": "Roll", //NO I18n
	"Date" : "Datum",//No I18n
	"crm.field.valid.decimal.check2" : "Decimaltecken f\u00f6r f\u00e4lt <i>{0}</i> ska vara mindre \u00e4n eller lika med {1}.",//No I18n
	"crm.field.empty.check" : "{0} f\u00e5r inte vara tomt.",//No I18n
	"crm.label.add.note": "L\u00e4gg till notering", //NO I18n
	"crm.label.simply.by": "fr\u00e5n", //NO I18n
	"crm.general.addnote": "L\u00e4gg till en anteckning", //NO I18n
	"crm.general.addtitle": "L\u00e4gg till en titel", //NO I18n
	"crm.label.attach.file": "Bifoga fil", //NO I18N
	"crm.button.mass.delete": "Radera", //NO I18N
	"crm.warning.delete.record": "\u00c4r du s\u00e4ker p\u00e5 att du vill flytta \"{0}\" till papperskorgen?", //NO I18N
	"crm.label.yes": "Ja", //NO I18N
	"crm.note.view.previous": "Visa tidigare anteckningar", //NO I18N
  "of": "av", //NO I18N
	"crm.label.notes": "Noteringar", //NO I18N
	"crm.note.recent.first": "Senaste f\u00f6rst", //NO I18N
	"crm.note.recent.last": "Senaste sist", //NO I18N
	"crm.territory.label.only": "{0} enbart", //no i18n
	"crm.select" : "V\u00e4lj",//No I18n
	"crm.button.apply.filter" : "Anv\u00e4nd filter",//No I18n
	"crm.alert.maximum.text.values.contains" : "Du kan inte l\u00e4gga till mer \u00e4n {0} v\u00e4rden f\u00f6r detta f\u00e4lt.",//No I18n
	"PM" : "PM",//No I18n
	"crm.mb.newversion.msg4" : "OK, jag f\u00f6rst\u00e5r!",//No I18n
	"Jan" : "Jan",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "Mars",//No I18n
	"Apr" : "Apr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Aug",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Okt",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dec",//No I18n
	"crm.label.More" :"Mer", //no i18n
	"crm.label.unmapped.stages":"Ej bekr\u00e4ftad", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Visa mer",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Visa mindre",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"TROLIGHET",//no i18n
"crm.lead.prediction.tooltip.score":"PO\u00c4NG",//no i18n
"crm.criteria.max.rowcnt.exceeds":"Du kan inte l\u00e4gga till ytterligare kriterier.",//no i18n
"Planned":"Planerad",//no i18n
"Invited":"Inbjuden",//no i18n
"Sent":"Skickat",//no i18n
"Received":"Mottagen",//no i18n
"Opened":"\u00d6ppnad",//no i18n
"Responded":"Svarade",//no i18n
"Bounced":"Studsade",//no i18n
"Opted\ Out":"Tackade nej",//no i18n
"crm.filter.label.with.open":"Med \u00f6ppen {0}",//no i18n
"crm.filter.label.without.any":"Utan n\u00e5gon {0}",//no i18n
"crm.filter.label.with.module":"Med {0}",//no i18n
"crm.filter.label.without.open":"Utan \u00f6ppna {0}",//no i18n
"crm.filter.label.activity.due":"{0} f\u00f6rfallet",//no i18n
"crm.filter.label.activity.done":"{0} Klart",//no i18n
"Notes":"Noteringar",//no i18n
"crm.filter.label.notes.added":"Noteringar har lagts till",//no i18n
"crm.label.filter.email.status":"Senaste e-poststatus",//no i18n
"crm.label.filter.email.clicked":"klickad",//no i18n
"crm.label.filter.email.responded":"svarat", //no i18n
"crm.label.filter.email.info":"Filtrera poster baserat p\u00e5 den senaste statusen f\u00f6r dina skickade/mottagna e-postmeddelanden.",//no i18n
"crm.filter.label.sent":"skickat",//no i18n
"crm.filter.label.not.sent":"ej skickat",//no i18n
"crm.filter.label.opened":"\u00f6ppnat",//no i18n
"crm.filter.label.not.opened":"ej \u00f6ppnat",//no i18n
"crm.filter.label.received":"mottaget",//no i18n
"crm.filter.label.not.received":"ej mottaget",//no i18n
"crm.filter.label.bounced":"studsat",//no i18n
"crm.filter.label.opened.not.replied":"\u00f6ppnade och inte besvarade", //no i18n
"crm.filter.label.any":"N\u00e5got av f\u00f6ljande",//no i18n
"crm.zia.config.potential.amount":"{0} belopp",//no i18n
"Quote\ Stage":"{0} Stadium",//no i18n
"crm.module.owner":"{0} \u00c4gare",//no i18n
"Potential\ Closing\ Date":"{0} f\u00e4rdigdatum",//no i18n
"crm.lead.prediction.likely.convert":"Trolig att konvertera",//no i18n
"crm.lead.prediction.convert.high":"H\u00f6g",//no i18n
"crm.lead.prediction.convert.medium":"Medium",//no i18n
"crm.lead.prediction.convert.low":"L\u00e5g",//no i18n
"crm.predictions.feature.label":"F\u00f6ruts\u00e4gelse",//no i18n
"crm.intelligence.prediction.likelywin":"Trolig vinnare",//no i18n
"crm.intelligence.prediction.likelylose":"Trolig f\u00f6rlorare",//no i18n
"crm.intelligence.prediction.halfchance":"50:50 chans",//no i18n
"crm.intelligence.prediction.score":"Prognospo\u00e4ng",//no i18n
"crm.lead.prediction.recent.score":"Senaste prognospo\u00e4ngen",//no i18n
"crm.intelligence.prediction.lastconv":"Senaste 3 konversationerna",//no i18n
"crm.intelligence.prediction.recordsfocus":"Register f\u00f6r fokusering",//no i18n
"crm.intelligence.prediction.slowmoving":"L\u00e5ngsam r\u00f6relse",//no i18n
"crm.intelligence.prediction.trend.down":"Nyligen dalande i trend",//no i18n
"crm.label.touched.records":"Ber\u00f6rda poster",//no i18n
"crm.label.untouched.records":"Or\u00f6rda poster",//no i18n
"crm.label.record.action":"Inspelnings\u00e5tg\u00e4rd",//no i18n
"workflow.rule.view.label.Modified":"\u00c4ndrad",//no i18n
"crm.label.not.modified":"Ej modifierad",//no i18n
"crm.label.related.records.action":"Relaterad post\u00e5tg\u00e4rd",//no i18n
"Done":"Klar",//no i18n
"crm.label.not.done":"Ej klart",//no i18n
"sentiment.model":"E-postk\u00e4nsla",//no i18n
"sentiment.criteria.count":"Antal",//no i18n
"sentiment.criteria.percentage":"Procent",//no i18n
"sentiment.criteria.lastmail":"F\u00f6r det senaste e-postmeddelandet",//no i18n
"Chats":"Chattar",//no i18n
"Attended":"N\u00e4rvarade",//no i18n
"crm.lead.prediction.popup.text":"N\u00e4r det \u00e4r troligt att omvandling \u00e4r {0}, ska prognosresultatet ligga mellan {1}.",//no i18n
"crm.lead.prediction.popup.final":"\u00c4ndra filtret s\u00e5 som beh\u00f6vs och f\u00f6rs\u00f6k igen.",//no i18n
"crm.custom.field.less.than.to1":"Fr\u00e5n-omr\u00e5de kan inte vara st\u00f6rre \u00e4n Till-omr\u00e5de.",//no i18n
"Last\ Activity\ Date":"Senaste aktivitetsdatum",//no i18n
"crm.label.vendor.name":"{0} Namn",//no i18n
"hours":"timmar",//no i18n
"days":"dagar",//no i18n
"weeks":"veckor",//no i18n
"months":"m\u00e5nader",//no i18n
"years":"\u00e5r",//no i18n
"crm.label.general.small.after":"efter",//no i18n
"Last\ Week":"F\u00f6rra Veckan",//no i18n
"Last\ Month":"F\u00f6rra M\u00e5naden",//no i18n
"crm.module.name":"{0} Namn",//no i18n
"Campaign":"Kampanj",//no i18n
"Tasks":"Uppgifter",//no i18n
"Calls":"Samtal",//no i18n
"Events":"H\u00e4ndelser",//no i18n
"sentiment.criteria.wrongcriteria":"Kriteriev\u00e4rdet kan inte vara h\u00f6gre \u00e4n {0}",//no i18n
"crm.chosen.minimum.input.text":"Ange {0} eller fler tecken",//no i18n
"crm.intelligence.prediction.trendup":"Upp\u00e5tg\u00e5ende trend",//no i18n
"crm.intelligence.prediction.trenddown":"Ned\u00e5tg\u00e5ende trend",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Genomf\u00f6rd" ,//no i18n
"crm.label.success":"Lyckades" ,//no i18n
"crm.label.Failure":"Misslyckades" ,//no i18n
"Both":"B\u00e5da" ,//no i18n
"crm.condition.cannot.empty":"Villkoret f\u00e5r inte vara tomt.",//no i18n
"crm.condition.last.30.days":"under de senaste 30 dagarna",//no i18n
"crm.condition.last.60.days":"under de senaste 60 dagarna",//no i18n
"crm.condition.last.90.days":"under de senaste 90 dagarna",//no i18n
"crm.sentiment.Positive":"Positiv",//no i18n
"crm.sentiment.Negative":"Negativ",//no i18n
"sentiment.positiveandnegative":"Positiv och negativ",//no i18n
"sentiment.positiveornegative":"Positiv eller negativ",//no i18n
"sentiment.positiveonly":"Positiv endast",//no i18n
"sentiment.negativeonly":"Negativ endast",//no i18n
"crm.sentiment.Neutral":"Neutral",//no i18n
"crm.filters.select.campaign.type":"V\u00e4lj {0}-typ",//no i18n
"crm.filters.select.campaign.status":"V\u00e4lj {0}-status",//no i18n
"campaign.Member" : "Medlem",//no i18n
	"Service":"Tj\u00e4nst",//no i18n
"Activities":"Aktiviteter",//no i18n
"crm.livedesk.pot.nextdays":"Kommande {0} dagar",//no i18n
"Today\ +\ Overdue":"Idag + F\u00f6rsenad",//no i18n
"crm.source.user.and.system":"Anv\u00e4ndare & System",//no i18n
"crm.source.user.or.system":"Anv\u00e4ndare eller system",//no i18n
"User":"Anv\u00e4ndare",//no i18n
"crm.source.user.only":"Endast via anv\u00e4ndare",//no i18n
"crm.source.system.only":"Endast av system",//no i18n
"Scheduled":"Schemalagt",//no i18n
"Attended\ Dialled":"N\u00e4rvarade uppringda",//no i18n
"Unattended\ Dialled":"Ej uppringd fr\u00e5nvaro",//no i18n
"Cancelled":"Annullerad",//no i18n
"crm.filter.email.isblocked":"\u00e4r blockerad",//no i18n
"crm.filter.email.isnotblocked":"\u00e4r ej blockerad",//no i18n
"condition.till.now":"Tills nu",//no i18n
"crm.recurring.no.months":"{0} m\u00e5nader",//no i18n
"crm.lead.prediction.tooltip":"Trolig att konvertera - Po\u00e4ngomr\u00e5de",//no i18n
"crm.website.activity":"Webbplatsaktivitet",//no i18n
"crm.label.By":"Av",//no i18n
"crm.chosen.searching.text":"S\u00f6ker...",//no i18n
"crm.label.memberstatus.is":"och medlemsstatus \u00e4r",//no i18n
"crm.events.duration":"Varaktighet",//no i18n
"crm.title.clear.name":"Rensa",//no i18n
"crm.label.status.is":"och status \u00e4r",//no i18n
"zia.last3.help":"Konversationen inkluderar samtal, uppgifter, {0}, mottagen e-post, anteckningar, bes\u00f6k, sociala kommentarer, support\u00e4renden fr\u00e5n Desk.",//no i18n
"crm.label.tag.related.to":"relaterad till",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Ny {0} kommer att skapas.",//No I18n
	"crm.krp.no.records.found" : "Ingen {0} hittades",//No I18n
	"crm.module.new" : "Ny {0}",//No I18n
	"crm.label.view" : "Visa",//No I18n
	"crm.nsocial.customers" : "Kunder",//No I18n
	"crm.nsocial.open.potential" : "\u00d6ppen {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u00d6vriga",//No i18n
	"crm.field.length.check" : "{0}-v\u00e4rdet \u00f6verskrider maxl\u00e4ngden.", //No I18n
	"crm.lower.now": "nu",//no i18n
	"crm.time.min.ago": "{0} minuter sedan",//no i18n
	"crm.time.mins.ago":"{0} minuter sedan",//no i18n
	"crm.time.hr.ago": "{0} timmar sedan",//no i18n
	"crm.time.hrs.ago": "{0} timmar sedan", //no i18n
	"AllUsers": "Alla anv\u00e4ndare", //no i18n"AllUsers": "All Users" //no i18n
	"crm.label.search":"S\u00f6k",//no i18n
	"crm.api.filterby":"Filtrera efter",//no i18n
	"crm.customview.nofields.found":"--Inga matchande f\u00e4lt--",//no i18n
	"crm.setup.system.ziarecommendation":"Rekommendation",//no i18n
	"crm.filter.label.all.products":"Alla {0}",//no i18n
	"crm.filter.label.select.products":"Markerad {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Granska processregistreringsstatus",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"V\u00e4lj ett v\u00e4rde.",//no i18n
	"crm.segmentation.segment.score":"Segmentbetyg",//no i18n
	"crm.filter.label.in":"baserat p\u00e5",//no i18n
	"crm.filter.label.and.purchase.in":"och troligtvis k\u00f6pas i",//no i18n
	"crm.filter.label.last.purchased":"och nyligen f\u00f6rv\u00e4rvad",//no i18n
	"crm.filter.label.a.day":"en Dag",//no i18n
	"crm.filter.label.a.week":"en Vecka",//no i18n
	"crm.filter.label.a.month":"en M\u00e5nad",//no i18n
	"crm.cal.custom":"Anpassa",//no i18n
	"crm.mb.field.common.empt":"V\u00e4rdet f\u00e5r inte vara tomt.",//no i18n
	"crm.chosen.error.loading.text":"Hoppsan, vi kunde inte l\u00e4sa in dina resultat",//no i18n
	"crm.filter.label.firstbuy":"F\u00f6rsta g\u00e5ngen",//no i18n
	"crm.filter.label.cwbab":"Beroende",//no i18n
	"crm.filter.label.fbt":"Paket",//no i18n
	"crm.filter.label.rebuy":"Repetition",//no i18n
	"crm.filter.label.nextbuy":"Sekvens",//no i18n
	"crm.mxnlookup.select" : "Tilldela {0}",//No I18n
	"crm.lookup.chooserecord":"V\u00e4lj {0}",//no i18n
	"crm.record.selected":"Markerad ({0})",//no i18n
	"crm.module.empty.message" : "Hittade ingen {0}",//No I18n
	"crm.mxnlookup.selected" : "Tilldelad {0}",//No I18n
	"crm.label.creator.noPermission" : "Beh\u00f6righet nekad", //No I18n
	"crm.security.error" : "Du har inte beh\u00f6righet att utf\u00f6ra den h\u00e4r \u00e5tg\u00e4rden. Kontakta administrat\u00f6ren.", //No I18n
	"crm.segmentation.recency" : "Nyligen", //No I18n
	"crm.segmentation.frequency" : "Frekvens", //No I18n
	"crm.segmentation.monetary" : "Monet\u00e4r", //No I18n
	"crm.smartfilter.related.module.msg" : "Du kan inte v\u00e4lja mer \u00e4n tre relaterade moduler.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(Ex: E-post, Aktiviteter, Anteckningar)",//No I18n
	"crm.smartfilter.related.module.msg2" : "Varaktighet kan inte vara tomt", //No I18n
	"crm.label.timeZone": "Tidszon", //NO I18n
	"crm.label.insufficient.privileges": "Otillr\u00e4cklig beh\u00f6righet f\u00f6r att utf\u00f6ra denna \u00e5tg\u00e4rd. Kontakta administrat\u00f6ren.", //NO I18n
	"crm.filter.header.secton.system": "Systemdefinierade filter", //NO I18N
	"crm.filter.header.secton.fields": "Filtrera efter f\u00e4lt", //NO I18N
	"crm.createfield.calcinfo.new" : "Detta f\u00e4lt fungerar som en kalkylator f\u00f6r uttryck du matar in.</br> <b>T.ex. 20+20</b> ger <b>40</b> automatiskt",//No i18n
	"crm.lable.read.only" : "Skrivskyddat f\u00e4lt",//No i18n
	"crm.column.sort.asc" : "Stigande",//No I18n
	"crm.column.sort.desc" : "Fallande",//No i18n
	"crm.column.unsort" : "Avbryt sortering",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Dela till kund", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Endast {0} tecken \u00e4r till\u00e5tna f\u00f6r {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Delad med kund", //NO I18N
	"crm.button.ok" : "OK",  //NO I18N
	"crm.role.already.selected" : "Denna roll har redan valts", //no i18n
	"crm.user.deleted": "ANV\u00c4NDAREN HAR RADERATS",  //NO I18N
	"crm.account.closed": "DET H\u00c4R KONTOT HAR ST\u00c4NGTS",  //NO I18N
	"crm.start.chat": "Starta chatt",  //NO I18N
	"crm.start.call": "Starta ett samtal",  //NO I18N
	"crm.recipient.invalid.email" : "Ogiltiga e-postmeddelanden hittades.", //NO I18N
    "crm.recipient.add.recipient" : "L\u00e4gg till ytterligare mottagare", //NO I18N
	"crm.start.video.call": "Starta ett videosamtal",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"Betygs\u00e4ttningsregler",
	"Score":"Po\u00e4ng",
	"Positive Score":"Positivt betyg",
	"Negative Score":"Negativt betyg",
	"Touch Point Score":"Touch-po\u00e4ngbetyg",
	"Positive Touch Point Score":"Positivt Touch-po\u00e4ngbetyg",
	"Negative Touch Point Score":"Negativt Touch-po\u00e4ngbetyg",
	"crm.label.type.minutes": "Skriv h\u00e4r p\u00e5 n\u00e5gra minuter", //NO I18N

	"is\ OPEN":"\u00e4r \u00d6PPEN",//no i18n
	"is\ WON":"har VUNNITS",//no i18n
	"is\ LOST":"har F\u00d6RLORATS",//no i18n
	"crm.potential.all.open":"Alla \u00f6ppna stadier",//no i18n
	"crm.potential.all.won":"Alla st\u00e4ngda stadier",//no i18n
	"crm.potential.all.lost":"Alla st\u00e4ngda f\u00f6rlorade stadier",//no i18n

	"crm.campaign.member.status" : "Medlemsstatus",//no i18n
	"crm.dashboard.select.type" : "V\u00e4lj {0}",//no i18n
	"crm.campaign.service.status":"Tj\u00e4nstestatus",//no i18n

	"crm.label.addColumn":"L\u00e4gg till kolumn",//no i18n
	"crm.button.clear.filter":"St\u00e4ng filter",//no i18n
	"crm.button.show.filter":"Visa filter",//no i18n
	"crm.las.error.user.maxlimit":"Du kan bara v\u00e4lja maximalt 20 anv\u00e4ndare.",//no i18n
	"crm.las.error.picklist.maxlimit":"Du kan bara v\u00e4lja maximalt 20 alternativ.",//no i18n

	"crm.fileuploader.message.responseerror": "\u00d6verf\u00f6ring misslyckades", //NO I18N
	"crm.storage.create.error":"Inga poster kan skapas d\u00e5 du har uppn\u00e5tt din maximala datalagringsgr\u00e4ns.",//no i18n
	"crm.storage.create.error.client":"Inga nya poster kan skapas eftersom din administrat\u00f6r har uppn\u00e5tt sin maximala lagringsgr\u00e4ns. Kontakta {0} f\u00f6r att l\u00f6sa detta problem.",//no i18n
	"crm.storage.avail.info":"({0} \u00e5terst\u00e5r av {1})",//no i18n
	"crm.storage.error.key.manage":"Hantera din datalagring",//no i18n
	"Records":"Poster",//no i18n
	"crm.workflow.alert.additional.recipients" : "Ytterligare mottagare", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Anv\u00e4nd kommatecken f\u00f6r att separera flera e-postadresser.", //NO I18N
	"crm.related.contact.account" : "{0} relaterad till {1}",//No I18n
	"crm.allcontact.show" : "Alla {0}",//No I18n
	"crm.button.mass.show" : "Visa",//No I18n
	"crm.msg.custom.view.not.replied" : "Ej besvarade meddelanden", //NO I18N
	"crm.msg.custom.view.replied" : "Besvarade meddelande",//NO I18N
	"crm.workflow.select.recipients" :"Mottagare", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"V\u00e4lj minst en profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Du kan tyv\u00e4rr inte ta bort en standardprofil.",//NO I18N
	"crm.inv.label.add.emails" : "L\u00e4gg till e-post", //NO I18N
	"crm.prediction.analytics.filter.year":"F\u00f6rra \u00e5ret",//no i18n
	"Previous\ FY":"F\u00f6reg\u00e5ende bokf\u00f6rings\u00e5r",//no i18n
	"Current\ FY":"Nuvarande bokf\u00f6rings\u00e5r",//no i18n
	"Next\ FY":"N\u00e4sta bokf\u00f6rings\u00e5r",//no i18n
	"Current\ FQ":"Nuvarande bokf\u00f6ringskvartal",//no i18n
	"Next\ FQ":"N\u00e4sta bokf\u00f6ringskvartal",//no i18n
	"Previous\ FQ":"Tidigare FQ",//no i18n
	"crm.picklist.sample.text":"Provtext",//no i18n
	"crm.more.colors":"Fler f\u00e4rger",//no i18n
	"crm.button.back.alone":"Tillbaka",//no i18n
	"crm.field.label.email":"E-post",//no i18n
	"crm.zia.nba.feature.label":"N\u00e4st b\u00e4sta \u00e5tg\u00e4rden",//no i18n
	"Meeting":"M\u00f6te",//no i18n
	"Tomorrow":"I morgon",//no i18n
	"crm.gdpr.notavailable.field":"Ej tillg\u00e4nglig",//no i18n
	"crm.setup.system.ziasimilarity":"Rekommendation om likhet",//no i18n
	"crm.gdpr.notavailable.field":"Ej tillg\u00e4nglig",//no i18n
	"crm.filter.label.all.products":"Alla {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} ska vara större än eller lika med {1}",
	"crux.users.selected.plural" : "{0} användare valda.",
	"crux.user.selected.singular" :"{0} användare vald.",
	"crux.criteria.empty.secondaryfield.module" : "Inget matchande {0}-fält hittat i {1}-modul",
	"crux.criteria.empty.secondaryfield" : "Inget annat {0}-fält tillgängligt för jämförelse, ange ett värde för att jämföra.",
	"crux.logged.in.role.definition" : "Användarrollen som initierar inspelningsåtgärderna",
	"zia.similarity.smartfilter.score":"Likhetspo\u00e4ng",//no i18n
	"zia.similarity.smartfilter.records":"Lika {0} av",//no i18n
	"zia.similarity.smartfilter.records.search":"Visa lika {0} av",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Du kan inte v\u00e4lja mer \u00e4n {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} ska vara större än eller lika med {1}",
	"crux.users.selected.plural" : "{0} användare valda.",
	"crux.user.selected.singular" :"{0} användare vald.",
	"crux.criteria.empty.secondaryfield.module" : "Inget matchande {0}-fält hittat i {1}-modul",
	"crux.criteria.empty.secondaryfield" : "Inget annat {0}-fält tillgängligt för jämförelse, ange ett värde för att jämföra.",
	"crux.logged.in.role.definition" : "Användarrollen som initierar inspelningsåtgärderna",
	"crux.max.limit.unselect" : "Du kan inte avmarkera fler än {0} {1}.",//NO I18N
	"crux.existing.tag" : "\u201d{0}\u201d är redan vald" //NO I18N
}
